import { useMemo } from 'react';

// Hooks
import { useTranslations } from 'next-intl';

// Utils
import { getWeekDay } from '@tools/ts-get-week-day';

export const useGetWeekDay = () => {
  const t = useTranslations('WEEKDAYS');

  const weekDays = useMemo(
    () => [
      t('SUNDAY'),
      t('MONDAY'),
      t('TUESDAY'),
      t('WEDNESDAY'),
      t('THURSDAY'),
      t('FRIDAY'),
      t('SATURDAY'),
    ],
    [t]
  );

  return useMemo(() => {
    const getWeekDayFn = (date: Date) => {
      return getWeekDay(date, weekDays);
    };

    return {
      getWeekDay: getWeekDayFn,
      weekDays,
    };
  }, [weekDays]);
};
