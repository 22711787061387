// Utils
import { rest } from 'shared/libs/rest';

// Query key
export const QUERY_KEY_QR_CODES = '/qr-codes';

// Types
import type { UserDataResponse } from 'modules/users/api/apiGetUsers';

// Api
import { QueuedUserDataResponse } from 'modules/queued-users/api/apiGetQueuedUsers';

export interface ActivePenaltyDataResponse {
  id: number;
  reason: string;
  activeTo: string;
}

export interface QrCodeDataResponse {
  category: 'A' | 'B' | 'C' | 'V';
  createdAt: string;
  hash: string;
  id: string;
  link: string;
  updatedAt: string;
  user: UserDataResponse;
}

export interface GetQrCodeParams {
  hash?: string;
}

export interface GetQrCodeResponse extends QrCodeDataResponse {
  activePenalty?: ActivePenaltyDataResponse;
  queue?: QueuedUserDataResponse;
  numberHidden: boolean;
}

export const apiGetQrCode = async (
  params: GetQrCodeParams = {}
): Promise<GetQrCodeResponse> => {
  const { hash } = params;

  return await rest.get(`${QUERY_KEY_QR_CODES}/${hash}`, false, {});
};
