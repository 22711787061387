import styled from 'styled-components';

// Components
import { div } from '@ui/base-html-tags';
import { Alert as UIAlert, AlertProps } from '../Alert';
import { Button } from '../Button';
import { QrCode as ParentQrCode } from 'modules/qr-codes/components/QrCode';

// Re export types
export type { AlertProps };

// Styled components
export const Wrap = styled(div)``;
export const ScannerWrap = styled(div)`
  width: 240px;
  height: 240px;
  margin: 0 auto;
`;
export const QrCode = styled(ParentQrCode).attrs(() => ({
  removable: false
}))`
  width: 240px;
  margin: 0 auto;
  height: auto;
  user-select: none;
  pointer-events: none;
`;
export const Alert = styled(UIAlert)`
  margin: 15px auto 0;
  max-width: 480px;
`;
export const RetryButton = styled(Button)`
  margin-left: 10px;
`;