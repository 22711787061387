import styled, { css } from 'styled-components';

// Components
import { div, span } from '@ui/base-html-tags';
import { Button as UIButton } from 'shared/ui/Button';
import { Link as UILink } from 'shared/ui/Link';
import { Alert as UIAlert, AlertProps } from 'shared/ui/Alert';

// Icons
import CrossSvg from 'public/icons/cross.svg';
export const Wrap = styled(div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const SubWrap = styled(div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px 20px;
  gap: 20px;
`;
export const TextWrap = styled(div)<{
  $center?: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 480px;

  ${({
  $center
}) => $center && css`
      align-items: center;
    `};
`;
export const Text = styled(span)`
  ${({
  theme
}) => css`
    font-weight: ${theme.fontWeight.bold};
  `};
`;
export const BorderedText = styled(Text)`
  ${({
  theme
}) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 380px;
    width: 100%;
    padding: 20px;
    font-size: 56px;
    border-radius: ${theme.borderRadius.s};
    border: 2px dashed ${theme.color.black};
  `};
`;
export const QueueNumber = styled(BorderedText)`
  width: 150px;
  height: 150px;
`;
export const BorderedTextSmall = styled(BorderedText)`
  font-size: 24px;
`;
export const Title = styled(Text)`
  text-align: center;
  font-size: 20px;
`;
export const Link = styled(UILink)`
  text-decoration: none;
  width: 100%;
  max-width: 320px;
`;
export const Button = styled(UIButton)`
  width: 100%;
  max-width: 320px;
`;
export const ErrorButton = styled(Button).attrs(() => ({
  EndAdornment: CrossSvg
}))`
  ${({
  theme
}) => css`
    height: auto;
    font-size: 20px;
    font-weight: ${theme.fontWeight.bold};
    background-color: ${theme.color.error};
  `};
`;
export const Alert = styled(UIAlert).attrs(() => ({
  showClose: false
}))<AlertProps>`
  max-width: 500px;
  width: 100%;
`;