export const defaultDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const getWeekDay = (date: Date, days: Array<string> = defaultDays) => {
  return days[date?.getDay?.()];
};
