import styled, { css } from 'styled-components';

// Components
import { div, img, span } from '@ui/base-html-tags';
import { HtmlHTMLAttributes } from 'react';

// Config
import { SHP_CATEGORY_COLORS } from 'config';

// Icons
import SHPLogoSvg from 'public/images/shp-logo-full.svg';

// Types
import type { QrCodeDataResponse } from 'modules/qr-codes/api/apiGetQrCodes';
export interface WrapProps extends HtmlHTMLAttributes<HTMLDivElement> {
  $dashedBorder?: boolean;
  $xs?: number;
  $category?: QrCodeDataResponse['category'];
  $removable?: boolean;
}

// Styled Components
export const TopWrap = styled(div)`
  position: relative;
  padding: 10px 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -10px;
`;
export const SHPFullLogo = styled(SHPLogoSvg)`
  width: 70%;
`;
export const WebSiteLink = styled(span)``;
export const Wrap = styled(div)<WrapProps>`
  width: 6.7cm;
  height: 9.5cm;
  font-family: sans-serif;
  @media print {
    break-inside: avoid;
  }
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  position: relative;
  overflow: hidden;

  ${({
  $dashedBorder,
  theme,
  $removable,
  $category
}) => {
  const bgColor: Record<QrCodeDataResponse['category'], string> = SHP_CATEGORY_COLORS[$category as any];
  return css`
      font-weight: ${theme.fontWeight.bolder};
      font-size: 26px;
      color: #000;
      background-color: ${theme.color.white};
      ${$dashedBorder && css`
        border: 1px dashed #000;
      `}

      ${TopWrap} {
        padding: 10px 20px;
        color: white;
        background-color: ${bgColor};
      }

      ${WebSiteLink} {
        font-size: 16px;
      }

      ${$removable && css`
        cursor: pointer;

        &:hover {
          &::before {
            content: 'Remove';
            font-size: 24px;
            padding: 10px 20px;
            color: ${theme.color.error};
            display: flex;
            justify-content: flex-end;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.6);
            z-index: 1;
          }
        }
      `};
    `;
}};
`;
export const BottomWrap = styled(div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-top: -20px;
`;
export const Image = styled(img)`
  width: 100%;
`;