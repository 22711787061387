// Hooks
import {
  useMutation,
  UseMutationOptions,
  RequestError,
} from 'shared/libs/rest';

// Api
import {
  QUERY_USER_CANCEL_QUEUE,
  apiUserCancelQueue,
  UserCancelQueueParams,
  UserCancelQueueResponse,
} from '../apiUserCancelQueue';

export const useUserCancelQueue = (
  defParams?: UserCancelQueueParams,
  options?: UseMutationOptions<
    UserCancelQueueResponse,
    RequestError,
    UserCancelQueueParams
  >
) =>
  useMutation<UserCancelQueueResponse, RequestError, UserCancelQueueParams>({
    mutationKey: QUERY_USER_CANCEL_QUEUE + '/patch',
    mutationFn: (params: UserCancelQueueParams) =>
      apiUserCancelQueue({
        ...(params || {}),
        ...(defParams || {}),
      } as UserCancelQueueParams),
    ...(options || ({} as any)),
  });
