// Components
import { MutationConfirmDialog, MutationConfirmDialogProps } from 'shared/ui/MutationConfirmDialog';

// Hooks
import { useAddUserIntoQueue } from 'modules/queues/api/hooks/useAddUserIntoQueue';
import { useTranslations } from 'next-intl';

// Types
import type { UseQueryResult } from '@tanstack/react-query';
import type { GetQrCodeResponse } from '../../api/apiGetQrCode';
export interface UserQueueDialogProps extends MutationConfirmDialogProps {
  query: UseQueryResult<GetQrCodeResponse>;
  mutation: ReturnType<typeof useAddUserIntoQueue>;
}
export const UserQueueDialog = ({
  query,
  asChild = true,
  cancelOnOk = true,
  mutation,
  ...props
}: UserQueueDialogProps) => {
  const t = useTranslations('QR_SCAN.USER_QUEUE_DIALOG');
  const qrCode = query?.data;
  const {
    hash
  } = qrCode || {};
  const {
    mutateAsync
  } = mutation;
  const handleOk = async () => {
    await mutateAsync({
      qrCodeHash: hash as string
    });
    await query?.refetch?.();
  };
  return <MutationConfirmDialog title={t('TITLE')} description={t('DESCRIPTION')} onOk={handleOk} {...{
    mutation,
    asChild,
    cancelOnOk
  }} {...props}>
      {props.children}
    </MutationConfirmDialog>;
};