// Components
import { MutationConfirmDialog, MutationConfirmDialogProps } from 'shared/ui/MutationConfirmDialog';

// Hooks
import { useUserCancelQueue } from 'modules/queues/api/hooks/useUserCancelQueue';
import { useTranslations } from 'next-intl';

// Types
import type { UseQueryResult } from '@tanstack/react-query';
import type { GetQrCodeResponse } from '../../api/apiGetQrCode';
export interface UserCancelQueueDialogProps extends MutationConfirmDialogProps {
  query: UseQueryResult<GetQrCodeResponse>;
}
export const UserCancelQueueDialog = ({
  query,
  asChild = true,
  cancelOnOk = true,
  ...props
}: UserCancelQueueDialogProps) => {
  const t = useTranslations('QR_SCAN.USER_CANCEL_QUEUE_DIALOG');
  const qrCode = query?.data;
  const {
    hash
  } = qrCode || {};
  const mutation = useUserCancelQueue();
  const {
    mutateAsync
  } = mutation;
  const handleOk = async () => {
    await mutateAsync({
      hash: hash as string
    });
    await query?.refetch?.();
  };
  return <MutationConfirmDialog title={t('TITLE')} description={t('DESCRIPTION')} onOk={handleOk} {...{
    mutation,
    asChild,
    cancelOnOk
  }} {...props}>
      {props.children}
    </MutationConfirmDialog>;
};