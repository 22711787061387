import React, { HtmlHTMLAttributes } from 'react';

// Styles
import { Wrap, ScannerWrap, QrCode, Alert, AlertProps, RetryButton } from './styles';

// Hooks
import { useTranslations } from 'next-intl';

// Components
import { QrScanner as ParentQrScanner, QrScannerProps as ParentQrScannerProps } from '@ui/qr-scanner';

// Types
import type { QrCodeDataResponse } from 'modules/qr-scan/api/apiGetQrCode';
export interface QrScannerProps extends HtmlHTMLAttributes<HTMLDivElement>, ParentQrScannerProps {
  data?: QrCodeDataResponse;
  loading?: boolean;
  error?: boolean;
  success?: boolean;
  warning?: boolean;
  showRetry?: boolean;
  onRetryClick?: () => void;
}
export const QrScanner = ({
  data,
  loading = false,
  onScan,
  paused,
  error,
  success = !!data,
  warning,
  showRetry = true,
  onRetryClick,
  ...props
}: QrScannerProps) => {
  const t = useTranslations('UI.QR_SCANNER');
  const loadingAlertProps: AlertProps = loading ? {
    type: 'info',
    message: t('CHECKING')
  } : {};
  const successAlertProps: AlertProps = success ? {
    type: 'success',
    message: <>
            {t('SUCCESS')}
            {showRetry && <RetryButton onClick={onRetryClick}>{t('RETRY')}</RetryButton>}
          </>
  } : {};
  const warningAlertProps: AlertProps = warning ? {
    type: 'warning',
    message: <>
            {t('WARNING')}
            {showRetry && <RetryButton onClick={onRetryClick} />}
          </>
  } : {};
  const errorAlertProps: AlertProps = error ? {
    type: 'error',
    message: <>
            {t('ERROR')}
            {showRetry && <RetryButton onClick={onRetryClick} />}
          </>
  } : {};
  return <Wrap {...props}>
      {data ? <QrCode data={data} /> : <ScannerWrap>
          <ParentQrScanner paused={loading || warning || error || success || paused} {...{
        onScan
      }} allowMultiple />
        </ScannerWrap>}

      <Alert type="info" message={t('POINT_YOUR_CAMERA')} {...errorAlertProps} {...successAlertProps} {...warningAlertProps} {...loadingAlertProps} show showClose={false} />
    </Wrap>;
};