// Utils
import { rest } from 'shared/libs/rest';

// Query key
export const QUERY_KEY_QUEUE_GET_IN_LINE = '/queue/get-in-line';

// Types
import type { QueuedUserDataResponse } from '../../queued-users/api/apiGetQueuedUsers';

export interface AddUserIntoQueueParams {
  qrCodeHash?: string;
}

export interface AddUserIntoQueueResponse extends QueuedUserDataResponse {}

export const apiAddUserIntoQueue = async (
  params: AddUserIntoQueueParams = {}
): Promise<AddUserIntoQueueResponse> => {
  return await rest.post(QUERY_KEY_QUEUE_GET_IN_LINE, false, params);
};
