// Hooks
import {
  useMutation,
  UseMutationOptions,
  RequestError,
} from 'shared/libs/rest';

// Api
import {
  QUERY_KEY_QUEUE_GET_IN_LINE,
  apiAddUserIntoQueue,
  AddUserIntoQueueParams,
  AddUserIntoQueueResponse,
} from '../apiAddUserIntoQueue';

// Request hook
export const useAddUserIntoQueue = (
  defParams?: AddUserIntoQueueParams,
  options?: UseMutationOptions<
    AddUserIntoQueueResponse,
    RequestError,
    AddUserIntoQueueParams
  >
) =>
  useMutation<AddUserIntoQueueResponse, RequestError, AddUserIntoQueueParams>({
    mutationKey: QUERY_KEY_QUEUE_GET_IN_LINE + '/post',
    mutationFn: (params: AddUserIntoQueueParams) =>
      apiAddUserIntoQueue({
        ...(params || {}),
        ...(defParams || {}),
      } as AddUserIntoQueueParams),
    ...(options || ({} as any)),
  });
