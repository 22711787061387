import { useQuery, UseQueryOptions } from 'shared/libs/rest';

// Api
import {
  apiGetQrCode,
  QUERY_KEY_QR_CODES,
  GetQrCodeParams,
  GetQrCodeResponse,
} from '../apiGetQrCode';

// Re export
export { QUERY_KEY_QR_CODES };

import type { RequestError } from 'shared/libs/rest';

export const useGetQrCode = (
  params: GetQrCodeParams = {},
  options?: Partial<UseQueryOptions<GetQrCodeResponse, RequestError>>
) => {
  return useQuery({
    queryKey: [QUERY_KEY_QR_CODES, params],
    queryFn: () => apiGetQrCode(params),
    placeholderData: (d) => d,
    ...(options || {}),
  });
};
